.btn-scroll-wrap{
   position: fixed; 
   right: 30px;
   bottom: 30px;
   z-index: 1;
   width: auto;
   height: auto;

   .btn-scroll{
      cursor: pointer;
      width: 50px;
      height: 50px;
      background-color: #fff;
      border-radius: 50%;
      text-align: center;
      box-shadow: -1px 12px 25px -5px rgba(153,153,153,0.40);
      -webkit-box-shadow: -1px 12px 25px -5px rgba(153,153,153,0.40);
      -moz-box-shadow: -1px 12px 25px -5px rgba(153,153,153,0.40);
      margin: auto;
      margin-bottom: 5px;
      position: relative;

      img{
         width: 20px;
         height: 20px;
         position: absolute;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
      }
   }

   span{
      font-size: .7rem;
      color: #5C5C5C;
   }
}
