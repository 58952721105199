@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in-text {
  animation: fadeIn 1s;
}

.hover-zoom{
  transition: transform .25s, visibility .25s ease-in;

  &:hover{
    transform: scale(1.07);
  }
}

.hover-zoom-big{
  transition: transform .25s, visibility .25s ease-in;

  &:hover{
    transform: scale(1.3);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.hover-rotate{
  &:hover{
    animation: rotation 7s infinite linear;
  }
}

/* GLITCH */
.glitch {
  transform: translate(4.5em,3em);
  animation: glitch-animate 3s infinite;
}
@keyframes glitch-animate {
  0%,26%,30%,72%,76%,100% { transform: translate(0em,0em) skew(0deg) ; box-shadow: none }
  30%,70% {transform: translate(0em,0em) skew(30deg);}
  29%,31%,69%,71% {transform: translate(0em,0em) skew(0deg);}
  28%,74% { box-shadow: 
    -0.2em 0.1em 0 0 cyan,
    0.2em -0.1em 0 0 magenta
  }
}
.glitch:after {
  animation: glitch-animate-after 3s infinite;
}
@keyframes glitch-animate-after {
  0%,34%,38%,46%,50%,100% { transform: translate(0,1em); box-shadow: none; }
  36%,48% { transform: translate(0.4em,0em);
  box-shadow: 
    -1em 0 0 0 cyan,
    1em 0 0 0 magenta,
    0.5em 1em 0 -0.8em cyan
  }
}
.glitch:before {
  transform: translate(0em,3em);
  animation: glitch-animate-before 3s infinite;
}
@keyframes glitch-animate-before {
  0%,30%,34%,50%,54%,100% { transform: translate(0,0); box-shadow: none; }
  32%,52% { transform: translate(-0.4em,1em);
  box-shadow: 
    -0.3em 0 0 0 red,
    0.3em 0 0 0 yellow}
}

/* END GLITCH */

/* PERSPECTIVE */
.perspec-back {
  animation: perspec-back 3s linear infinite;
}
@keyframes perspec-back {
  0%,100% { transform: translate(4em,0) }
  25% { transform: translate(3.4em,0) }
  50% { transform: translate(1em, 0.6em) }
  75% { transform: translate(3em,0.6em) }
}
.perspec-middle {
  animation: perspec-middle 3s linear infinite;
}
@keyframes perspec-middle {
  0%,100% { transform: translate(-1em,0.4em) }
  25% { transform: translate(1em,0.4em) }
  50% { transform: translate(0em,1.2em) }
  75% { transform: translate(1em,1.2em) }
}
.perspec-front {
  animation: perspec-front 3s linear infinite;
}
@keyframes perspec-front {
  0%,100% { transform: translate(6.5em,1em) }
  25% { transform: translate(7.5em,1em) }
  50% { transform: translate(7.5em,2em) }
  75% { transform: translate(6.5em,2em) }
}
/* END PERSPECTIVE */