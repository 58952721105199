.page404{
  height: 100vh;
  background: linear-gradient(180deg, rgb(254, 243, 242) 0%, rgb(254, 243, 242) 70%, rgba(255, 255, 255, 0) 98%);
  
  .bg-header{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;

    h5{
      font-size: 6rem;
      text-align: center;
      font-family: 'Lora-Bold';
    }
  }
}