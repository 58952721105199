// colors
$color-dark: #00387a;
$color-border: #ccc;
$color-trans-bg: #ededed;
// easings
$ease-cb: cubic-bezier(.4,0,.2,1);
$ease-cb-2: cubic-bezier(.19,1,.22,1);
$ease-cb-3: cubic-bezier(.77,0,.175,1);
$ease-cb-4: cubic-bezier(.99,1,.92,1);
// padding
$pad: 3em;
// mqs
$mq-med: 54em;
$mq-large: 65em;
$mq-xlarge: 91em;

$slide-content-height: calc(100vh - 105.5px);


//------------------------------
//  nth-trans-delay()
//  Transition Delay on nth-childs
//  @param: $delay_items: number of nth-of-type items to create
//  @param: $delay_time: transition-dealy value
//-----------------------------
@mixin nth-trans-delay($delay_items: 7, $delay_time: 0.2s){
  @for $i from 1 through $delay_items {
    &:nth-child(#{$i}) {   
     transition-delay: $delay_time * $i; 
    }
  }
}

//-------------------------------
//  Slides Nav
//-------------------------------
.slides-nav{
  z-index: 99;
  position: absolute;
  // Grimey, not sure why
  // 0 isn't working
  right: 5%;
  display: flex;
  align-items: flex-end;
  height: 100%;
  color: #111;
  
  @media (min-width: $mq-med){
    right: 2%;
  }

  &__nav{
    position: relative;
    right: 30px;
    bottom: 30px;
    display: block;
    font-size: 1em;
    transform-origin: center;
    border-radius: 30px;
    overflow: hidden;
  }

  button{
    position: relative;
    display:inline-block;
    padding: 0.35em 25px;
    margin: 0;
    appearance: none;
    background:transparent;
    border: 0;
    overflow-x:hidden;
    transition: color 0.5s ease;
    cursor:pointer;
    
    &:hover{
      background-color: #00387a;
      color: #fff;
    }
    
    &:focus{
      outline: 0;
    }
  }
  // If is Sliding, prevent 
  // double clicks
  .is-sliding &{
    pointer-events:none;
  }
}

//-------------------------------
//  Slides
//-------------------------------
.slides{
  position: relative;
  display: block;
  height: $slide-content-height;
  width:100%;
  background: #fff;
  transition: background 1s $ease-cb-4;

  .is-sliding &{
    background: $color-trans-bg;
    transition: background 0.3s $ease-cb-4;
  }
}

//-------------------------------
//  Single Slide
//-------------------------------
$slide-content-size-sm: 100%;
$slide-content-size: 100%;

.slide{
  z-index: -1;
  padding: 0;
  position: absolute;
  width: 100%;
  height: $slide-content-height;
  transition: z-index 1s ease;
  
  // Bring active layer to foreground
  &.is-active{
    z-index: 19;
    transition: z-index 1s ease;
  }

  &__content{
    position: relative;
    margin: 0 auto;
    height: $slide-content-size-sm;
    width: $slide-content-size-sm;
    top: (100% - $slide-content-size-sm) / 2;
    
    @media (min-width: $mq-med){
      height: $slide-content-size;
      width: $slide-content-size;
      top: (100% - $slide-content-size) / 2;
    }
  }

  &__header{
    z-index: 9;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    transform: translateX(5%);
    
  }

  &__title{
    overflow-y: hidden;
     
    // Slice text animation
    .title-line{
      display: block;
      overflow-y: hidden;

      .header{
        font-size: 4rem;
      }

      .subtitle{
        max-width: 450px;
        padding-right: 60px;
      }

      span{
        display: inline-block;
        transform:translate3d(0,140%,0);
        opacity: 0;
        transition: transform 0.4s ease, opacity 0.8s ease;
        @include nth-trans-delay(2, 0.15s);
      }

      .is-active & span{
        transform:translate3d(0,0%,0);
        opacity: 1;
        transition: transform 0.6s $ease-cb-3, opacity 0.1s ease;
      }
      .is-active &:nth-of-type(2n) span{
        transition-delay:0.2s;
      }
    }
  }
  
  // Image wrapper
  &__figure{
    z-index: 7;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
     
    // Scale Image
    // .is-sliding &{
    //   filter: grayscale(100%);
    //   height: 100%;
    //   transition: height 0.1s $ease-cb-2;
    // }
  }
  &__img{
    position: relative;
    display: block;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
    height: 100%;
    width: 100%;
  }
}
